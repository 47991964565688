import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentLang, setLang } from 'utils/lang';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import './LngDropdown.scss';
import { Icon, Dropdown } from '@reservamos/elements';
import flagUsa from 'images/languages/flag-usa.svg';
import flagMex from 'images/languages/flag-mex.svg';
import flagCol from 'images/languages/flag-col.svg';
import flagBrz from 'images/languages/brazil-flag-icon.svg';
import flagFrance from 'images/languages/france_flag.svg';
import flagNetherlands from 'images/languages/netherlands_flag.svg';
import flagPeru from 'images/languages/peru_flag.svg';

const LanguageCodes = [
  {
    flag: flagUsa,
    title: 'English',
    lng: 'en-US',
  },
  {
    flag: flagMex,
    title: 'Español',
    lng: 'es-MX',
  },
  {
    flag: flagCol,
    title: 'Español',
    lng: 'es-CO',
  },
  {
    flag: flagPeru,
    title: 'Español',
    lng: 'es-PE',
  },
  {
    flag: flagFrance,
    title: 'Français',
    lng: 'fr-FR',
  },
  {
    flag: flagNetherlands,
    title: 'Nederlands',
    lng: 'NL',
  },
  {
    flag: flagBrz,
    title: 'Português',
    lng: 'pt-BR',
  },
];

const LngDropdown = () => {
  const features = useWhitelabelFeatures();
  const env = useWhitelabelEnvs();
  const { i18n } = useTranslation();
  const currentLangCode = CurrentLang(); // Get the current language code

  // Find the current language
  const currentLang = LanguageCodes.find((langCode) => langCode.lng === currentLangCode);

  // Filter available languages based on environment settings
  const availableLanguages = LanguageCodes.filter((code) => env.lang.available.includes(code.lng));

  // Early return if language change is not allowed or there are 2 or fewer available languages
  if (!features.ALLOW_TO_CHANGE_LANGUAGE || availableLanguages.length <= 2) return null;

  /**
   * Change the language.
   * @param {Object} langCode - The language code.
   */
  const ChangeLanguage = (langCode) => {

    setLang(i18n, langCode.lng);
    window.location.reload();
  };

  return (
    <div className="language-dropdown">
      <Dropdown trigger={<Icon type={currentLang.flag} />}>
        <React.Fragment key=".0">
          {availableLanguages.map((language) => (
            <button
              className="flag-button"
              onClick={() => ChangeLanguage(language)}
              key={language.lng}
            >
              <Icon type={language.flag} />
              <p>{language.title}</p>
            </button>
          ))}
        </React.Fragment>
      </Dropdown>
    </div>
  );
};

export default LngDropdown;
