import React from 'react';
import { useSelector } from 'react-redux';
import { When } from 'react-if';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Spacing, Text, Icon } from '@reservamos/elements';
import emojiParty from 'images/icons/emoji-party.png';
import SendEmail from 'components/purchase/SendEmail';
import 'styles/components/purchase/PurchasesLayout';
import PurchaseToken from 'ui/atoms/PurchaseToken';
import PaymentStepStatus from '../../components/purchase/PaymentStepStatus/PaymentStepStatus';
import TicketSummary from './TicketSummary';
import PaymentStatusIcon from '../../components/purchase/PaymentStatusIcon';

/**
 * DownloadTickets Component
 *
 * Component for displaying downloadable tickets with associated information such as departure and return details, payment status, and email instructions. Utilizes child components like TicketSummary and PaymentStatusIcon.
 *
 * @component
 * @param {string} hrefDeparture - The URL for downloading the departure ticket.
 * @param {string} hrefReturn - The URL for downloading the return ticket.
 * @param {string} instructions - Additional instructions or information.
 * @param {boolean} isReturnOpen - Indicates whether the return ticket information is open.
 * @param {boolean} isDepartureOpen - Indicates whether the departure ticket information is open.
 * @param {Object} status - Object containing payment and ticket status information.
 * @param {string} email - The email associated with the ticket.
 * @param {string} departsTransporterKey - Transporter key for departure.
 * @param {string} returnsTransporterKey - Transporter key for return.
 * @param {boolean} displayDownloadTicketsButton - Indicates whether to display the download tickets button.
 * @param {string} departureDate - The date of departure.
 * @param {string} departureOriginTime - The departure time for the origin.
 * @param {string} departureOriginTerminal - The departure terminal for the origin.
 * @param {string} departureOriginCity - The city of departure for the origin.
 * @param {string} departureOriginLocation - The location link for departure origin.
 * @param {string} departureDestinationTime - The arrival time for the destination.
 * @param {string} departureDestinationTerminal - The arrival terminal for the destination.
 * @param {string} departureDestinationCity - The city of arrival for the destination.
 * @param {string} departureDestinationLocation - The location link for departure destination.
 * @param {string} returnDate - The date of return.
 * @param {string} returnOriginTime - The departure time for the return origin.
 * @param {string} returnOriginTerminal - The departure terminal for the return origin.
 * @param {string} returnOriginCity - The city of departure for the return origin.
 * @param {string} returnOriginLocation - The location link for return origin.
 * @param {string} returnDestinationTime - The arrival time for the return destination.
 * @param {string} returnDestinationTerminal - The arrival terminal for the return destination.
 * @param {string} returnDestinationCity - The city of arrival for the return destination.
 * @param {string} returnDestinationLocation - The location link for return destination.
 * @param {string} token - The purchase token
 *
 * @returns {JSX.Element} - The rendered DownloadTickets component.
 */

const DownloadTickets = ({
  hrefDeparture,
  hrefReturn,
  instructions,
  isReturnOpen,
  isDepartureOpen,
  email,
  status,
  departsTransporterKey,
  returnsTransporterKey,
  displayDownloadTicketsButton,
  departureDate,
  departureOriginTime,
  departureOriginTerminal,
  departureOriginCity,
  departureOriginLocation,
  departureDestinationTime,
  departureDestinationTerminal,
  departureDestinationCity,
  departureDestinationLocation,
  returnDate,
  returnOriginTime,
  returnOriginTerminal,
  returnOriginCity,
  returnOriginLocation,
  returnDestinationTime,
  returnDestinationTerminal,
  returnDestinationCity,
  returnDestinationLocation,
  token,
}) => {
  const { features, env } = useSelector((state) => state.whitelabelConfig);
  const { t } = useTranslation('purchase');
  const displayPurchaseToken = features.TICKETS_REFERENCE === 'purchase-token';
  const hideViewLocation = features.HIDE_LOCATION_ON_PURCHASE_COMPLETE;
  const displayTransporterKey =
    features.TICKETS_REFERENCE === 'transporter-key' && env.brand === 'cds';

  return (
    <Spacing vertical size="XXL">
      <div className="purchase-layout-content-wrapper">
        <Spacing vertical>
          <Spacing vertical size="XS">
            <Text weight="bold" size="XL">
              {t('label.tickets_ready')}
              <Icon type={emojiParty} hasMargin marginSide="left" />
            </Text>
            <Text color="grayMedium">{instructions}</Text>
          </Spacing>
          {token && <PurchaseToken token={token} />}
        </Spacing>
        <PaymentStatusIcon />
      </div>

      <When condition={displayDownloadTicketsButton}>
        <Spacing vertical>
          <When condition={displayTransporterKey}>
            <PurchaseToken
              token={displayTransporterKey ? departsTransporterKey : token}
              displayTransporterKey={displayTransporterKey}
            />
          </When>
          <TicketSummary
            title={t(!isDepartureOpen ? 'ticket_departure' : 'ticket_departure_open')}
            date={departureDate}
            originTime={departureOriginTime}
            originTerminal={departureOriginTerminal}
            originCity={departureOriginCity}
            originLocation={hideViewLocation ? '' : departureOriginLocation}
            destinationTime={departureDestinationTime}
            destinationTerminal={departureDestinationTerminal}
            destinationCity={departureDestinationCity}
            destinationLocation={hideViewLocation ? '' : departureDestinationLocation}
            buttonLabel={t(!isDepartureOpen ? 'get_ticket' : 'get_ticket_open')}
            href={hrefDeparture}
          />
          <When condition={displayPurchaseToken}>
            <Text size="S">
              {`${t('purchase_reference')} `}
              <Text size="S" elementType="span" weight="bold">
                {departsTransporterKey}
              </Text>
            </Text>
          </When>
          {returnOriginCity && (
            <>
              <When condition={displayTransporterKey}>
                <PurchaseToken token={returnsTransporterKey} displayTransporterKey isRound />
              </When>
              <TicketSummary
                title={t(!isReturnOpen ? 'ticket_return' : 'ticket_return_open')}
                date={returnDate}
                originTime={returnOriginTime}
                originTerminal={returnOriginTerminal}
                originCity={returnOriginCity}
                originLocation={hideViewLocation ? '' : returnOriginLocation}
                destinationTime={returnDestinationTime}
                destinationTerminal={returnDestinationTerminal}
                destinationCity={returnDestinationCity}
                destinationLocation={hideViewLocation ? '' : returnDestinationLocation}
                buttonLabel={t(!isDepartureOpen ? 'get_ticket' : 'get_ticket_open')}
                href={hrefReturn}
              />
              <When condition={displayPurchaseToken}>
                <Text size="S">
                  {`${t('purchase_reference')} `}
                  <Text size="S" elementType="span" weight="bold">
                    {returnsTransporterKey}
                  </Text>
                </Text>
              </When>
            </>
          )}
        </Spacing>
      </When>
      <Spacing flexGrow isResponsive>
        <Spacing vertical size="S">
          <PaymentStepStatus
            transporterType="bus"
            status={status.sent}
            title="sent"
            email={email}
          />
          <Text size="S">{t('payment:text.check_spam_email')}</Text>
        </Spacing>
        <SendEmail />
      </Spacing>
    </Spacing>
  );
};

DownloadTickets.propTypes = {
  hrefDeparture: PropTypes.string,
  hrefReturn: PropTypes.string,
  instructions: PropTypes.string,
  isReturnOpen: PropTypes.bool,
  isDepartureOpen: PropTypes.bool,
  status: PropTypes.shape({
    payment: PropTypes.string.isRequired,
    tickets: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
  }).isRequired,
  email: PropTypes.string,
  departsTransporterKey: PropTypes.string,
  returnsTransporterKey: PropTypes.string,
  displayDownloadTicketsButton: PropTypes.bool,
  departureDate: PropTypes.string.isRequired,
  departureOriginTime: PropTypes.string,
  departureOriginTerminal: PropTypes.string.isRequired,
  departureOriginCity: PropTypes.string.isRequired,
  departureOriginLocation: PropTypes.string.isRequired,
  departureDestinationTime: PropTypes.string.isRequired,
  departureDestinationTerminal: PropTypes.string.isRequired,
  departureDestinationCity: PropTypes.string.isRequired,
  departureDestinationLocation: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  returnOriginTime: PropTypes.string,
  returnOriginTerminal: PropTypes.string,
  returnOriginCity: PropTypes.string,
  returnOriginLocation: PropTypes.string,
  returnDestinationTime: PropTypes.string,
  returnDestinationTerminal: PropTypes.string,
  returnDestinationCity: PropTypes.string,
  returnDestinationLocation: PropTypes.string,
  token: PropTypes.string,
};

DownloadTickets.defaultProps = {
  hrefDeparture: '',
  hrefReturn: '',
  instructions: '',
  email: '',
  departureOriginTime: '',
  returnOriginTime: '',
  returnOriginTerminal: '',
  returnOriginCity: '',
  returnOriginLocation: '',
  returnDestinationTime: '',
  returnDestinationTerminal: '',
  returnDestinationCity: '',
  returnDestinationLocation: '',
  token: false,
};
export default DownloadTickets;
