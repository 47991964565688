import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Button, Spacing, Text, Modal } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import LoadingScreen from 'components/LoadingScreen';
import PaymentStatusTitle from 'components/purchase/PaymentStatusTitle';
import HelpContact from 'components/purchase/HelpContact';
import CardPaymentInfo from 'components/purchase/CardPaymentInfo';
import OxxoPaymentInfo from 'components/purchase/OxxoPaymentInfo';
import PaycashPaymentInfo from 'components/purchase/PaycashPaymentInfo';
import PurchasePricing from 'components/purchase/PurchasePricing';
import TicketItinerary from 'components/purchase/TicketItinerary';
import TicketPassengers from 'components/purchase/TicketPassengers';
import DotersPointsSummary from 'ui/atoms/DotersPointsSummary';
import EvertecPaymentInfo from 'components/purchase/EvertecPaymentInfo';
import EvertecPaymentDetails from 'components/purchase/EvertecPaymentDetails';
import CSSVariablesProvider from 'components/CSSVariablesProvider';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import CashPaymentInfo from '../../CashPaymentInfo';
import BrandLogo from '../../../BrandLogo';
import TransferPaymentTicket from '../../TransferPaymentTicket';
import parsePurchaseStatus from '../../../../utils/parsePurchaseStatus';
import PaypalPaymentInfo from '../../PaypalPaymentInfo';
import BankDetails from '../../BankDetails';
import DownloadTicketsContainer from '../../DownloadTicketsContainer';
import useReactNativeMessage from '../../../../hooks/useReactNativeMessage';
import PixPaymentInfo from '../../PixPaymentInfo';
import EfectyPaymentInfo from '../../EfectyPaymentInfo/EfectyPaymentInfo';
import wayIsOpenTicket from '../../../../utils/wayIsOpenTicket';
import EvaluateButton from '../../../../ui/atoms/EvaluateButton';
import PurchaseLayout from '../../../../ui/layouts/PurchaseLayout';
import 'styles/components/purchase/PurchasesLayout';
import NequiPaymentInfo from '../../NequiPaymentInfo';
import AdjacentSeat from '../../../../ui/atoms/AdjacentSeat';

const PurchaseComplete = ({
  isFetching,
  getPurchase,
  pollPurchaseComplete,
  loaded,
  paymentEngine,
  paymentType,
  paymentProvider,
  purchaseStatus,
  paymentStatus,
  token,
  trackPurchase,
  trackPurchaseHandler,
  transferPaymentState,
  isOpenTicket,
  email,
  setError,
  departsTicketPdfUrl,
  departsTransporterKey,
  returnTicketPdfUrl,
  returnsTransporterKey,
  isExchange,
  purchase,
}) => {
  const {
    env,
    copies: { provider },
    features,
  } = useSelector((state) => state.whitelabelConfig);
  const [isTracked, setIsTracked] = useState(!trackPurchase);
  const { t } = useTranslation('purchase');
  const RNMessage = useReactNativeMessage();
  const { theme, brand } = useWhitelabelEnvs();
  const { FUNNEL_STYLE } = useWhitelabelFeatures();
  const tenantUsesRemoteTickets = features.REMOTE_TICKETS;
  const canDotersGenerate = Boolean(purchase.walletAccrualPoints || purchase.walletPointsUsed);
  const isEvertecPayment = paymentEngine === 'evertec';
  const isEvertecPending = paymentStatus === 'attempt' && isEvertecPayment;
  const isYunoPending =
    (paymentStatus === 'pending' || paymentStatus === 'attempt') && paymentEngine === 'yuno';

  useEffect(() => {
    if (!loaded) getPurchase(token);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paymentStatus === 'chargeback' ||
      purchaseStatus === 'chargeback' ||
      purchaseStatus === 'canceled'
    ) {
      setError(301, 'error_when_generating_purchase', 'error', true);
    }

    if (
      (loaded && purchaseStatus === 'pending' && paymentStatus === 'charged') ||
      isEvertecPending ||
      isYunoPending
    ) {
      pollPurchaseComplete(token);
    }
    // when purchase complete remove lastPurchase token from storage
    // so no longer needed to call unlock seats
    if (loaded && purchaseStatus === 'completed' && paymentStatus === 'charged') {
      window.sessionStorage?.removeItem('lastPurchaseToken');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, loaded, purchaseStatus, paymentStatus, pollPurchaseComplete]);

  useEffect(() => {
    if (loaded && !isTracked) {
      setIsTracked(true);
      trackPurchaseHandler();
    }
  }, [isTracked, loaded, trackPurchaseHandler]);

  // Only for Mobile app webview
  useEffect(() => {
    // Verify if payment has succeed
    if (purchaseStatus === 'completed' && paymentStatus === 'charged') {
      RNMessage.post({ success: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseStatus, paymentStatus]);

  // Only for Mobile app webview
  useEffect(() => {
    // Verify if payment has succeed
    if (paymentType === 'paypal' && purchaseStatus === 'attempt') {
      RNMessage.post({ showPaypalPayment: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentType, purchaseStatus]);

  if (purchaseStatus === 'chargeback' || isFetching || trackPurchase) return <LoadingScreen />;

  const isCompleted = purchaseStatus === 'completed';
  const isAttempt = purchaseStatus === 'attempt';
  const showTransferDetails = paymentType === 'transfer' && transferPaymentState === 'OK';

  const showCardPayment = !isCompleted && paymentType === 'credit_card' && !isEvertecPayment;
  const showPaypalPayment =
    (paymentType === 'paypal' || paymentType === 'third_party') && isAttempt;
  const showCashPayment = !isCompleted && paymentType === 'store' && paymentEngine === 'kushki';
  const showOxxoPayment = paymentType === 'store' && !isCompleted && paymentEngine === 'conekta';
  const showPaycashPayment = paymentType === 'store' && !isCompleted && paymentEngine === 'paycash';
  const isPixPayment = paymentProvider === 'pix';
  const showPixPaymentDetails = isPixPayment && !isCompleted;
  const isEfectyPayment = paymentProvider === 'efecty';
  const isNequiPayment = paymentProvider === 'nequi';
  const showEfectyPaymentDetails = isEfectyPayment && !isCompleted;
  const showNequiPaymentDetails = isNequiPayment && !isCompleted;
  // const displayPaymentStatus = !isPixPayment;
  const pendingStatus = Boolean(purchaseStatus === 'attempt' || paymentStatus === 'pending');



  return (
    <div className="l-complete">
      <Header isFixed>
        <BrandLogo />
      </Header>
      <PaymentStatusTitle />
      <PurchaseLayout
        isFlat
        sidebar={
          <Spacing size="XL" vertical>
            <Spacing vertical size="S">
              <Text weight="bold">{t('title.check_trip_itinerary')}</Text>
              <Modal
                title={t('title.trip_details')}
                modalTrigger={<Button text={t('button.view_itinerary')} color="primary" />}
                responsiveSize="L"
              >
                <CSSVariablesProvider
                  theme={theme}
                  funnelStyle={FUNNEL_STYLE}
                  funnelStyleBrandVariation={brand.toUpperCase()}
                >
                  <div className="trip-summary-itinerary-purchase-complete">
                    <TicketItinerary showSeats={false} showTickets showDownloadTicketsStep />
                  </div>
                </CSSVariablesProvider>
              </Modal>
            </Spacing>
            <TicketPassengers />
            <Spacing vertical>
              <Text size="L" weight="bold">
                {t(isExchange ? 'label.exchange_details' : 'label.purchase_details')}
              </Text>
              <PurchasePricing />
            </Spacing>
            {showTransferDetails && <TransferPaymentTicket />}
            {isEvertecPayment && <EvertecPaymentDetails />}
            <BankDetails />
          </Spacing>
        }
      >
        <Spacing vertical>
          {isCompleted && (
            <Spacing vertical size="L">
              <DownloadTicketsContainer
                hrefDeparture={departsTicketPdfUrl}
                hrefReturn={returnTicketPdfUrl}
                instructions={t('ticket_instructions_digital', { context: env.brand })}
                isDepartureOpen={wayIsOpenTicket(purchase.departs)}
                isReturnOpen={wayIsOpenTicket(purchase.returns)}
                status={parsePurchaseStatus(purchaseStatus, paymentType)}
                isOpenTicket={isOpenTicket}
                displayDownloadTicketsButton={!tenantUsesRemoteTickets}
                email={email}
                departsTransporterKey={departsTransporterKey}
                returnsTransporterKey={returnsTransporterKey}
                token={token}
              />
              <div>
                <AdjacentSeat title={t('adjacent_seat.title')} text={t('adjacent_seat.message')} />
              </div>
              <div>
                <EvaluateButton />
              </div>
              {/* {isOpenTicket || isHybridTrip && <ExchangeCTA />} TODO: Removes comment after the mixed ticket branch gets merged */}
            </Spacing>
          )}
          {showCardPayment && <CardPaymentInfo />}
          {showCashPayment && <CashPaymentInfo />}
          {showOxxoPayment && <OxxoPaymentInfo />}
          {showPaypalPayment && <PaypalPaymentInfo />}
          {showPaycashPayment && <PaycashPaymentInfo />}
          {showPixPaymentDetails && <PixPaymentInfo />}
          {showEfectyPaymentDetails && <EfectyPaymentInfo />}
          {isEvertecPending && <EvertecPaymentInfo />}
          {showNequiPaymentDetails && <NequiPaymentInfo />}
          <Spacing vertical />
          {canDotersGenerate && (
            <DotersPointsSummary
              dotersUsed={purchase.walletPointsUsed}
              dotersEarned={purchase.walletAccrualPoints}
              pendingStatus={pendingStatus}
            />
          )}
        </Spacing>
        <HelpContact
          email={provider.email}
          phones={provider.phones || []}
          invoiceUrl={provider.invoiceUrl}
          whatsapp={provider.whatsapp || []}
        />
      </PurchaseLayout>
    </div>
  );
};

PurchaseComplete.propTypes = {
  getPurchase: PropTypes.func.isRequired,
  pollPurchaseComplete: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  paymentEngine: PropTypes.string,
  paymentType: PropTypes.string,
  paymentProvider: PropTypes.string,
  purchaseStatus: PropTypes.string,
  paymentStatus: PropTypes.string,
  token: PropTypes.string.isRequired,
  trackPurchase: PropTypes.bool.isRequired,
  trackPurchaseHandler: PropTypes.func.isRequired,
  transferPaymentState: PropTypes.string,
  isOpenTicket: PropTypes.bool.isRequired,
  email: PropTypes.string,
  setError: PropTypes.func.isRequired,
  departsTicketPdfUrl: PropTypes.string,
  departsTransporterKey: PropTypes.string,
  returnTicketPdfUrl: PropTypes.string,
  returnsTransporterKey: PropTypes.string,
  purchase: PropTypes.object,
  isExchange: PropTypes.bool,
};

PurchaseComplete.defaultProps = {
  paymentEngine: null,
  transferPaymentState: null,
  departsTicketPdfUrl: '',
  departsTransporterKey: '',
  returnTicketPdfUrl: '',
  returnsTransporterKey: '',
};

export default PurchaseComplete;
