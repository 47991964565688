import React from 'react';
import { useTranslation } from 'react-i18next';
import { CurrentLang, setLang } from 'utils/lang';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import './LngButton.scss';
import { Icon } from '@reservamos/elements';
import flagUsa from 'images/languages/flag-usa.svg';
import flagMex from 'images/languages/flag-mex.svg';
import flagCol from 'images/languages/flag-col.svg';

const LanguageCodes = [
  {
    flag: flagUsa,
    title: 'Switch to English site',
    lng: 'en-US',
  },
  {
    flag: flagMex,
    title: 'Cambiar sitio a Español',
    lng: 'es-MX',
  },
  {
    flag: flagCol,
    title: 'Cambiar sitio a Colombia',
    lng: 'es-CO',
  },
];

/**
 * Language button component.
 */
const LngButton = () => {
  // Importing necessary hooks and utilities
  const features = useWhitelabelFeatures();
  const env = useWhitelabelEnvs();
  const { i18n } = useTranslation();
  const currentLng = CurrentLang(); // Get the current language code

  // Get the list of available languages from the environment settings
  const availableLanguages = env.lang.available;

  // Check if language change is allowed and if there are more than 2 available languages
  // If either condition is not met, return null and do not render the component
  if (!features.ALLOW_TO_CHANGE_LANGUAGE || availableLanguages.length > 2) return null;

  // Find the next language to switch to, which is different from the current language
  const nextLng = availableLanguages.find((lang) => lang !== currentLng);

  // Find the language code object for the next language
  const languageCode = LanguageCodes.find((langCode) => langCode.lng === nextLng);

  /**
   * Change the language.
   */
  const ChangeLanguage = () => {
    setLang(i18n, languageCode.lng);
    window.location.reload();
  };

  return (
    <button className="flag-button" onClick={() => ChangeLanguage()} title={languageCode.title}>
      <Icon type={languageCode.flag} />
    </button>
  );
};

export default LngButton;
